import React from 'react';
//imagenes 
import a from '../IMG/Marca/Mrc_1.png';
import b from '../IMG/Marca/Mrc_1.png';
import c from '../IMG/Marca/Mrc_1.png';
import Mapa from '../IMG/Mapa/Map_1.png';
import Suc from '../IMG/LogosAspel/Suc.jpg';
import { Link } from 'react-router-dom';
import '../Component/styles/Stand1.css';
import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import Publi1 from '../IMG/CasosdeExito/Publi1.png';
import L1 from '../IMG/LogosEmpresas/MuseoNA.jpeg';
import L2 from '../IMG/LogosEmpresas/PATRONATO2.jpeg';
import L3 from '../IMG/LogosEmpresas/G_SELCA.png';
import L4 from '../IMG/LogosEmpresas/curtisl.png';
import L5 from '../IMG/LogosEmpresas/AIDI_I.jpeg';
import DatosC2 from '../paginas/AreasInteres/DatosCur2';
import Rel1 from '../IMG/Servicios/Datos curiosos/DatosCuriosos.jpg';
import Rel2 from '../IMG/Servicios/Tips.jpg';
import Rel3 from '../IMG/Servicios/Corte.jpg';
import Rel4 from '../IMG/Servicios/Deducibles.jpg';
import CarruselTEcnm from '../paginas/DetallesAComercial/CarruselTEcnm';
import CarruselTEcnm2 from '../paginas/DetallesAComercial/CarruselTEcnm2';
import CarruselTEcnm3 from '../paginas/DetallesAComercial/CarruselTEcnm3';
import CarruselTEcnm4 from '../paginas/DetallesAComercial/CarruselTEcnm4';
import { Reveal, Tween } from 'react-gsap';
//Aqui mandamos a llmar nuestros estilos para hacer el cambio de flechas - slider

class BootstrapCarousel extends React.Component {
  // Carrusel CLientes
  render() {
    return (
      <>
        <br />
        {/* <h3 align="center">NUESTROS CLIENTES</h3> */}
        <br />
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <center>
            <div class="carousel-inner">
              <div class="carousel-item active LEmpre2">
                {/* <Link to={"/"} style={{ textDecoration: 'none' }}> */}
                  <img src={L5} className="LEmpre" alt="" style={{filter: 'drop-shadow(10px 10px 10px #5F78B3)', borderRadius: '8px 8px 8px 8px'}} /> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={L4} className="LEmpre" alt="" style={{filter: 'drop-shadow(10px 10px 10px #5F78B3)', borderRadius: '8px 8px 8px 8px'}}/>
              </div>

              <div className="carousel-item LEmpre2">
                  <img src={L4} className="LEmpre" alt="" style={{filter: 'drop-shadow(10px 10px 10px #5F78B3)'}} /> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={L5} className="LEmpre" alt="" style={{filter: 'drop-shadow(10px 10px 10px #5F78B3)', borderRadius: '8px 8px 8px 8px'}}/>
              </div>

              <div class="carousel-item LEmpre2">
                  <img src={L3} className="LEmpre" alt="" style={{filter: 'drop-shadow(10px 10px 10px #5F78B3)', borderRadius: '8px 8px 8px 8px'}}/>
              </div>
            </div>
          </center>



          <a class="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
            <img src={izquierda} className="col-4 FIzquierda" alt="" />
            <span class="sr-only ">Previous</span>
          </a>


          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <img src={derecha} className="col-4 FDerecha" alt="" />
            <span class="sr-only">Next</span>
          </a>
        </div>


        <br />
        <br />
        <br />
        
      </>
    )
  }
}

export default BootstrapCarousel; 