import React from 'react';
import PQ1 from '../IMG/SolucionesTecnologicas/OCA_PQ_1.jpg';
import PQ2 from '../IMG/SolucionesTecnologicas/OCA_PQ_2.jpg';
import PQ3 from '../IMG/SolucionesTecnologicas/OCA_PQ_3.jpg';
import PQ4 from '../IMG/SolucionesTecnologicas/OCA_PQ_4.jpg';
import FondoForm from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO.jpg';
import FondoForm2 from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO_1.jpg';
import FondoForm3 from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO_2.jpg';
import Boton from './Boton'
import A from '../IMG/modal/Ev1.png';
import A1 from '../IMG/modal/icono-inf-tecnol-gica.png';
import L1 from '../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import L2 from '../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import L3 from '../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import L4 from '../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import L5 from '../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import L6 from '../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import L7 from '../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import A2 from '../IMG/modal/icon-backup.png';
import A3 from '../IMG/modal/icon-seguridad.png';
import A4 from '../IMG/modal/ms-office.png';
import A5 from '../IMG/modal/distribuidorintegral.png';
import B from '../IMG/modal/Ev2.png';
import C from '../IMG/modal/Ev3.png';
import D from '../IMG/modal/Ev4.png';
import E from '../IMG/modal/Ev5.png';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import BF1 from '../IMG/BFISKUR/cfdi-cancelados 1.png';
import BF2 from '../IMG/BFISKUR/comprobantes-pago-faltantesl 1.png';
import BF3 from '../IMG/BFISKUR/cumplimiento-obligaciones 1.png';
import BF4 from '../IMG/BFISKUR/efos 1.png';
import BF5 from '../IMG/BFISKUR/medidor-riesgo-fiscal 1.png';
import BF6 from '../IMG/BFISKUR/cfdi-recibido-reglas 1.png';
import BF7 from '../IMG/BFISKUR/repse-registro-proveedores 1.png';
import BF8 from '../IMG/BFISKUR/triple-conciliacion 1.png';
import ServOCA from '../IMG/OCA/Servicios/ServiciosOCA.jpg';
import LogoAspel1 from '../IMG/LogosAspel/LogoAspel.png';
import PQE from '../IMG/LogosAspel/LogoAspel.png';
import Medall from '../IMG/Logo/medalla-aspel-removebg-preview_orig.png';
import '../Component/styles/Servicios.css';
import { Reveal, Tween } from 'react-gsap';
import B1 from '../IMG/Logo/Bfis.png';
import FormularioSNT from '../paginas/Formulario/Conexion';
import Solucion from '../IMG/SolucionesTecnologicas/OCA_C_6.jpg';
import Eligenos from '../IMG/Diseños_SNT/TecnoOCA2.png';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter
} from 'reactstrap';

class SolucionesTec extends React.Component {

    render() {

        return (
            <>
                {/* <br /> */}
                {/* <Reveal repeat>
                    <Tween
                        from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0%', opacity: 1, rotation: 0 }}
                        duration={1.1}
                    >
                        <h2 ClassName="py-4 titSNT" align="center" style={{backgroundColor:'red'}}><b>Soluciones tecnológicas de gestión empresarial </b></h2>
                    </Tween>
                </Reveal> */}
                <br />
                <div className="row container-fluid">
                    <div className="col-12 col-sm-8 col-lg-8 col-xl-8 col-xxl-8">
                        <h2 className="titSNT2 py-1" align="center"><span className="SNTtit">¿Por qué Elegirnos?</span></h2>

                        {/* Inicia ¿Por qué elegirnos? */}
                        {/* Inicia Seccion ¿Por qué elegirnos? */}
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                                <div className="row">
                                    <div className="col-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-4 text-right">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -1, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <img src={PQ1} className="SolImaTec" alt=""></img>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-8 Mov_text text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -1, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ">
                                                    Somos una empresa especializada en <strong>tecnología de la ​información y consultoría. </strong>
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                                <div className="row">
                                    <div className="col-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-4 text-right">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -5, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <img src={PQ2} className="SolImaTec" alt="" ></img>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-8 Mov_text2 text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -5, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ">
                                                    Contamos con un equipo de <strong>ingenieros</strong> rigurosamente <strong>evaluados y certificados</strong> que te  apoyarán y te  brindaran soporte cuando lo necesites.
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                                <div className="row">
                                    <div className="col-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-4 text-right" >
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -12, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <img src={PQ3} className="SolImaTec" alt="" ></img>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-8 Mov_text2 text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -12, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ text-justify">
                                                    Brindamos <strong>soluciones integrales</strong> de alta calidad sin importar el tamaño o complejidad del proyecto.
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Termina Seccion ¿Por qué elegirnos? */}

                        {/* Termina ¿Por qué elegirnos? */}
                        {/* <img src={PQ4} className="" width="100%" height='510px' ></img> */}
                    </div>
                    <div className="col-12 col-sm-4 col-lg-4 col-xl-4 col-xxl-4">
                        <img src={Eligenos} className="mr-auto SoTecno821" width="100%" height="100%" alt="Acompañamos la evolición de tu negocio" />
                    </div>
                </div>
            </>

        )

    }

}

export default SolucionesTec;