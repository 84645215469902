import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../../Component/styles/CarruselInicio.css';
import $ from 'jquery';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';
import ServOCA from '../DetallesEventos/Evento1';
import { FormGroup, Label, Input, Button, Jumbotron, Container, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import emailjs from '@emailjs/browser';
import '../elementos/FormulaEMAIL2.css';

class CarruselInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name18: '',
            apellido18: '',
            abrirModalSNT18: false,
            ACmodal18: false
        }
        this.form = React.createRef();
        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            ACmodal18: !this.state.ACmodal18
        });
    }
    abrirModalSNT18 = () => {
        this.setState({
            abrirModalSNT18: true,
        });
    }
    handleChange = (e) => {
        const { value } = e.target
        this.setState({ name: value })
    }
    handleChange2 = (e) => {
        const { value } = e.target
        this.setState({ apellido: value })
    }
    handleReset = () => {
        Array.from(document.querySelectorAll("input, textarea")).forEach(
            input => (input.value = ""),
            textarea => (textarea.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    };

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, this.form.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => {
            this.toggle();
            console.log('Hola: ' + result.text);
            this.handleReset();
        },
            (error) => {
                alert('Surprise...');
                console.log(error.text);
            });
    };
    sendEmail2 = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.template_t6git15, this.form.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => {
            this.toggle();
            console.log('Hola 2: ' + result.text);
            this.handleReset();
        },
            (error) => {
                alert('Surprise...');
                console.log(error.text);
            });
    };

    componentDidMount() {

    }

    render() {
        const imgArr = [
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/OCA_C_1.jpg',
                alt: 'First slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/ProACT_OcaConsultoria7.jpg',
                alt: 'Second slide',
                url: '/#CExito'
            },
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/OCA_C_2.jpg',
                alt: 'Third slide',
                url: '/SistemasAspel'
            },
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/OCA_3.jpg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/OCA_2.jpg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'imgCarrusa',
                src:
                    'https://ocaconsultoria.mx/Aspel/OCA_3.jpg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            }
        ];

        const linkArr = [
            {
                className: 'img-size2',
                url: '../Footer.js'
            }
        ];
        return (
            <>
                <Modal isOpen={this.state.ACmodal18} toggle={this.toggle}>
                    <ModalHeader style={{ textAlign: 'left', backgroundColor: '#F2F2F2' }} toggle={this.toggle}>
                        <div className="container">
                            <h4 className="text-justify">Datos enviados exitosamente a <strong><i>OCA Consultoría en T.I</i></strong></h4>
                            <hr style={{ borderTop: '1px dashed #292563' }} />
                            <span style={{ fontSize: '10pt' }}>En Oca Consultoría en T.I. estamos comprometidos a brindarte una solucion a tu medida. <br /> En breve el equipo de OCA se comunicara contigo para atender tus necesitades.</span>
                        </div>
                    </ModalHeader>
                    <ModalFooter align="center">
                        <img src={logo} className="mr-auto" width="30%" alt="logo-OCA" />
                        <Button style={{ backgroundColor: '#292563' }} onClick={this.toggle}>ACEPTAR</Button>
                    </ModalFooter>
                </Modal>
                <div className="">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-9 col-xl-9 col-xxl-9" style={{ backgroundColor: '#000139' }}>
                            <div
                                id='myCarousel'
                                className='carousel slide'
                                data-ride='carousel'
                            >
                                <ol className='carousel-indicators'>
                                    <li
                                        data-target='#myCarousel'
                                        data-slide-to='0'
                                        className='active'
                                    ></li>
                                    <li data-target='#myCarousel' data-slide-to='1'></li>
                                    <li data-target='#myCarousel' data-slide-to='2'></li>
                                    <li data-target='#myCarousel' data-slide-to='3'></li>
                                    <li data-target='#myCarousel' data-slide-to='4'></li>
                                    <li data-target='#myCarousel' data-slide-to='5'></li>
                                </ol>
                                <div className="carousel-inner DivImaCarHome">
                                    {imgArr.map((item, index) => (
                                        <div
                                            key={index}
                                            className={
                                                index === 0 ? 'carousel-item active' : 'carousel-item'
                                            }
                                        >
                                            <a href={item.url}>
                                                <img
                                                    className={item.className}
                                                    src={item.src}
                                                    alt={item.alt}
                                                />
                                                {/* <button type="button" class="btn btn-info">Info</button> */}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                                <a
                                    className='carousel-control-prev'
                                    href='#myCarousel'
                                    role='button'
                                    data-slide='prev'
                                >
                                    <span
                                        className='carousel-control-prev-icon1'
                                        aria-hidden='true'
                                    >
                                        {' '}
                                    </span>
                                    <span className='sr-only'>Previous</span>
                                </a>
                                <a
                                    className='carousel-control-next'
                                    href='#myCarousel'
                                    role='button'
                                    data-slide='next'
                                >
                                    <span
                                        className='carousel-control-next-icon1'
                                        aria-hidden='true'
                                    ></span>
                                    <span className='sr-only'>Next</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-xxl-3" style={{ backgroundColor: '#000139' }}>
                            <div className="Con18For">
                                <Card className="CardFormulario3" style={{ border: 'transparent', backgroundColor: '#000139' }}>
                                    <CardHeader className="pb-3 HeCardForm" style={{ backgroundColor: '#000139' }}>
                                        {/* <h1>Datos de Contacto</h1> */}
                                        <p className="pb-4">
                                        </p>
                                        <p className="pb-4"></p>
                                        {/* <p className="pb-4"></p> */}
                                    </CardHeader>
                                    <CardBody className="CardFormulario2 formuForm" style={{ backgroundColor: '#b9c9ea' }}>
                                        <h6 className="Con18For2" >Datos de contacto</h6>
                                        <form className='cf' ref={this.form} onSubmit={this.sendEmail}>
                                            <div className='cf'>
                                                <input type='text' placeholder='Nombre' name='to_name' minLength={3} maxLength={35} className="inputForm PlaceFormu" required />
                                                <input type='text' placeholder='Apellido' name='to_apellido' minLength={3} maxLength={35} className="inputForm PlaceFormu" required />
                                                <input type='email' placeholder='Email' name='from_name' pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" maxLength={50} className="inputForm PlaceFormu" required />
                                                <input type='text' placeholder='Teléfono' name='to_telefono' minLength={10} maxLength={15} className="inputForm PlaceFormu" required />
                                            </div>
                                            <div className='cf'>
                                                <textarea name='message' type='text' placeholder='Dudas o Comentarios' minLength={1} maxLength={800} className="inputForm TextFormHome PlaceFormu" required ></textarea>
                                            </div>
                                            <input type='submit' value='Enviar' id='input-submit' />
                                            <div className="form-check pt-3 ">
                                                <div className="row">
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        <div className="col-1">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                                                        </div>
                                                        <div className="col-11">
                                                            <p className="pl-1" style={{ width: '180px', marginTop: '2px' }}> Acepto <a href="/AvisoP"> Aviso de privacidad </a></p>
                                                        </div>
                                                    </label>

                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                    <CardFooter style={{backgroundColor:'#F7F7F7'}}>
                                        <h5 className="text-justify text-center h5tama18">Atenderemos tus dudas y te asesoraremos con la información que necesites. </h5>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default CarruselInicio; 