import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../Menu';
import Footer from '../Footer';
import '../../Component/styles/DetEventos.css';
import video from '../../Video/Aspel/Video Oficial museo.mp4';
import A from '../../IMG/OCA/avatar-2027363_1280.png';
import OCA from '../../IMG/OCA/Team-1.jpg';
import OCA1 from '../../IMG/OCA/Team1.jpg';
import OCA2 from '../../IMG/OCA/Team2.jpg';
import OCA3 from '../../IMG/OCA/Team3-1.jpg';
import OCA4 from '../../IMG/OCA/Certificados.jpg';
import OCA_1 from '../../IMG/OCA/1-1.jpg';
import OCA_2 from '../../IMG/OCA/1-2.jpg';
import OCA_3 from '../../IMG/OCA/1-3.jpg';
import OCA_4 from '../../IMG/OCA/2-1.jpg';
import OCA_5 from '../../IMG/OCA/2-2.jpg';
import I1 from '../../IMG/OCA/Imagen2.jpg';
import I2 from '../../IMG/OCA/Imagen3.jpg';
import I3 from '../../IMG/OCA/Imagen4.jpg';
import I4 from '../../IMG/CAMBIOS OCA/EV2.png';
import H from '../../IMG/OCA/TaemH.jpg';
import M from '../../IMG/OCA/TeamM.jpg';
import LOG from '../../IMG/Logo/oca-consultoria-logo-ii.png';
import ReactGA from 'react-ga4';
import { Reveal, Tween } from 'react-gsap';
import {
  Modal, ModalHeader, ModalFooter, ModalBody, Button,
  Card, CardImg, CardTitle, CardText, CardGroup,
  CardSubtitle, CardBody,
  FormGroup, Form
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";

class Evento4 extends React.Component {



  state = {
    modalInicio: true,
    modalCerrar: false,
  };

  mostrarModalInicio = () => {
    this.setState({
      modalInicio: true,
    });
  };
  cerrarModalInicio = () => {
    this.setState({
      modalInicio: false,
    });
  };

  // 
  componentDidMount = async () => {
    ReactGA.initialize('G-LV1HVF1Y4N');
    ReactGA.send({ hitType: "pageview", page: "/Nosotros", title: "Nosotros" });
  }


  render() {



    return (
      <>
        <div className="fondoNosotroskbc">
          {/* ----------------- */}

          <Menu />

          <br />
          <br />
          <br />
          <br />
          <Modal isOpen={this.state.modalInicio} size="lg" toggle={this.toggle}
            aria-labelledby="example-modal-sizes-title-sm" centered>
            <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
              <h6 className=" modal-title text-center col-12" id="exampleModalLabel">EVENTOS OCA CONSULTORIA EN T.I. &nbsp;

                <button type="button" class="close" aria-label="Close" onClick={this.cerrarModalInicio}>
                  <span aria-hidden="true">&times;</span>
                </button></h6>
            </ModalHeader>


            <ModalBody style={{ backgroundColor: '#eff3fb' }}>
              {/* <video src={video} width="100%" loop controls muted autoplay="true" preload="auto"></video> */}
              <center>
                <h9 className="m-auto text-center" align="center"><b>Más Eventos</b></h9>
              </center>
              <CardGroup>

                <Card style={{ backgroundColor: '#eff3fb' }}>
                  <CardImg top src={I1} width="100%" className="py-2 container" alt="Card image cap" />
                  <hr color="#292563" />
                  <CardBody>

                    <CardTitle tag="h4" align="center">Eventos OCA CONSULTORIA EN T.I.</CardTitle>
                    <CardSubtitle><h7>Solicita más información para conocer acerca de los proximos eventos...</h7></CardSubtitle>
                    <CardText className="py-2 text-center">
                      <img src={LOG} width="100%" />
                    </CardText>
                  </CardBody>
                </Card>


                <Card style={{ backgroundColor: '#eff3fb' }}>
                  <CardImg top src={I2} width="100%" className="pt-2 container" alt="Card image cap" />
                  <CardImg bottom src={I4} width="100%" className="pb-2 container" alt="Card image cap" />
                </Card>



              </CardGroup>

            </ModalBody>

            <ModalFooter style={{ backgroundColor: '#eff3fb' }}>
              <div class="bd-highlight ml-auto"><Link onClick={this.cerrarModalInicio} type="button" class="btn" style={{ backgroundColor: '#c5cbd5' }}>CERRAR</Link></div>
            </ModalFooter>

          </Modal>
          <main role="main">
            <div className="container align-items-center">
              <div class="row">

                <div class="col-sm-12 pb-5">
                  <h1 class="Arr821 pt-4">Conocenos</h1> <br />
                  <h2 class="Arr8211 p-3" > <strong> OCA Consultoria en T.I.</strong> </h2>
                  <p class="card-text text-justify par1">
                    Somos una empresa de tecnología con más de 15 años de experiencia especializada en brindar soluciones tecnológicas a empresas pymes. Nuestro enfoque se centra en ayudar a nuestros clientes a optimizar sus procesos y mejorar su competitividad a través de la implementación de tecnología empresarial y los sistemas Siigo Aspel.<br />
                    Contamos con un equipo de ingenieros altamente capacitados, evaluados y certificados en diversas áreas de la tecnología, lo que nos permite ofrecer un servicio de calidad y adaptado a las necesidades específicas de cada cliente. Nos enorgullece poder brindar soluciones personalizadas y eficientes que ayudan a nuestros clientes a alcanzar sus objetivos comerciales.<br />
                    Nuestros servicios abarcan desde la consultoría tecnológica, la implementación, desarrollo, mantenimiento a aplicaciones tecnológicas trabajando bajo los mejores estándares de calidad que existen en el mercado, el soporte técnico continuo, garantizando la satisfacción y el éxito de nuestros clientes en el uso de la tecnología para potenciar su negocio. Estamos comprometidos con la innovación, la excelencia y el servicio al cliente, y trabajamos incansablemente para ser un aliado estratégico en el crecimiento y la evolución de las empresas pymes a través de la tecnología empresarial.
                    <br /><center><strong>#TeamOCA</strong></center>
                  </p>
                  {/* <div class="bd-highlight"><Link onClick={this.mostrarModalRegistrar} type="button" class="btn btn-success">ENTRAR A LA CONFERENCIA</Link></div> */}
                  <hr color="#61dafb" />
                </div>
                {/* IMAGENES OCA */}
                <div class="col-sm-3 align-items-center p-0">
                  <div className="card border-0 align-items-center" >
                    {/* Lado Izquierdo */}
                    {/* <Reveal repeat>
                    <Tween
                      from={{ opacity: -8, rotation: 1 }}
                      to={{ opacity: 1, rotation: 0 }}
                      duration={1.2}
                    >
                      <img src={OCA2} className="BRSNT SombrillaSNT" width="95%"></img>
                    </Tween>
                  </Reveal> */}
                  </div>
                  <div class="col-sm-12 align-items-center p-0">
                    <div className="card border-0" >
                      {/* <img src={H} className=" IDE12SNT SombrillaSNT"></img> */}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 align-items-center p-0 ">
                  <div className="card border-0 m-auto">
                    {/* Principal */}
                    {/* <img src={OCA1} className="AcomodoSNT align-items-center" width="100%"></img> */}
                  </div>
                </div>
                <div class="col-sm-3 align-items-center p-0">
                  <div className="card border-0 align-items-center" >
                    {/* <Reveal repeat>
                    <Tween
                      from={{ opacity: -6, rotation: 1 }}
                      to={{ opacity: 1, rotation: 0 }}
                      duration={1.2}
                    >
                      <img src={M} className="BRSNT2 SombrillaSNT" width="95%" height="90%"></img>
                    </Tween>
                  </Reveal> */}
                  </div>
                  <div class="col-sm-12 align-items-center p-0">
                    <div className="card border-0 align-items-center">
                      {/* <Reveal repeat>
                      <Tween
                        from={{ opacity: -8, rotation: 1 }}
                        to={{ opacity: 1, rotation: 0 }}
                        duration={1.2}
                      >
                        <img src={OCA} className=" IDE18SNT SombrillaSNT" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}></img>
                      </Tween>
                    </Reveal> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Reveal repeat>
              <Tween
                from={{ opacity: -3, rotation: 1 }}
                to={{ opacity: 1, rotation: 0 }}
                duration={1.1}
              >

                <center>
                  <h5 className="card-title" align="center">En OCA Consultoria te brindamos un servicio de calidad y un acompañamiento riguroso durante la evolucion de tu negocio.</h5>
                </center>
              </Tween>
            </Reveal> */}

            </div>
            <br />
          </main >

          <Footer />
        </div>

      </>

    )
  }

}


export default Evento4;