import React from 'react';
import '../Component/styles/Servicios.css';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter, CardHeader, Alert, Nav, NavItem, NavLink, Navbar
} from 'reactstrap';
import ServiciosOCA from '../paginas/DetallesEventos/Evento1';
import '../Component/styles/Canaco.css';
import FondoCiber from '../IMG/AlianzasOCA/UNIVDEP.jpg';
import Menu2 from './Menu';
import LogoOCA from '../IMG/Logo/oca-consultoria-logo-ii.png';

class Univdep extends React.Component {

    render() {

        return (
            <>
                <Menu2 />
                <div className="FondoCanaco">
                <img src={FondoCiber} className="SolImaTec" alt="" ></img>
                    {/* <video src={viCana} loop muted autoplay="true" preload="auto" className="videoCanaco"></video> */}
                    <div className="row">
                        <div className="col-3 col-sm-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="card justify-content-center" style={{ background: 'transparent', position: 'fixed', padding: '0% 0', marginTop: '286px', zIndex: '1' }}>

                                <nav className="navbar navbar-expand-lg navbar-light bg-light nav flex-column SerInCana" style={{ position: 'fixed', zIndex: '1' }} >
                                    <a class="navbar-brand" href="#">Menu</a>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon" style={{border:''}}></span>
                                    </button>
                                    

                                    <div class="collapse navbar-collapse nav flex-column" id="navbarTogglerDemo03" style={{ width: '200px' }}>
                                        {/* <form class="form-inline my-2 my-lg-0">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search" />
                                                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form> */}
                                        {/* <a class="nav-link" href="#ADM1">Configura y emite correctamente CFDI 4.0 en Aspel ADM <span class="sr-only">(current)</span></a> */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM1" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Configura y emite correctamente CFDI 4.0 en Aspel ADM <span class="sr-only">(current)</span>
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM2" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de RFC en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM3" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de usuarios en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM4" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Cómo cargar los sellos digitales en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Alta de RFC en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Cómo cargar los sellos digitales en ADM</a> */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM5" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de Cliente con ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM6" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de Tercero con ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Alta de Cliente con ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta producto con ADM</a> */}



                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM7" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta producto con ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM8" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de Documento tipo Compra con ADM
                                        </Button>

                                        {/* **********++++++++ */}


                                        {/* <a class="nav-link" href="#">Alta de Tercero con ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de Documento tipo Compra con ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM9" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Notas de venta en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM10" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Movimientos de Entrada y Salida en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Notas de venta en ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Emisión de la factura global en ADM</a> */}



                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM11" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de la factura global en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM12" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Factura a Mostrador con y sin desglose de IVA con ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Movimientos de Entrada y Salida en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Factura a Mostrador con y sin desglose de IVA con ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM13" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Elaboración de una devolución en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM14" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración de impuestos en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Elaboración de una devolución en ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de Documento tipo Honorarios</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM15" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de Documento tipo Honorarios
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM16" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración Cliente complemento ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Configuración de impuestos en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Configuración Cliente complemento ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM17" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de un comprobante de pago en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM18" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración Notas de crédito en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Emisión de un comprobante de pago en ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Emisión de la factura con el complemento de Leyendas Fiscales en ADM</a> */}
                                        {/*  */}


                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM19" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de la factura con el complemento de Leyendas Fiscales en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM20" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de documento tipo notas de crédito
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Configuración Notas de crédito en ADM</a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de documento tipo notas de crédito</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM21" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Cancelación del CFDI en Aspel ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM22" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Comprobante de recepción de pagos v 2.0 en ADM
                                        </Button>

                                        {/* **********++++++++ */}


                                        {/* <a class="nav-link" href="#">Cancelación del CFDI en Aspel ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Como imprimir los documentos en ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM23" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Como imprimir los documentos en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM24" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Consideraciones para la emisión del recibo de nómina en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Comprobante de recepción de pagos v 2.0 en ADM</a>
                                        <a class="nav-link disabled" href="#" tabind    ex="-1" aria-disabled="true">Consideraciones para la emisión del recibo de nómina en ADM</a> */}


                                        {/* <a class="nav-link" href="#">Cancelación del CFDI en Aspel ADM</a> */}


                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM25" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de documento Tipo Cotización en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM26" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de documento tipo Arrendamiento en ADM
                                        </Button>

                                        {/* **********++++++++ */}
                                        {/* <a class="nav-link" href="#">Alta de documento tipo Arrendamiento en ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Como imprimir los documentos en ADM</a> */}
                                        {/* <form class="form-inline my-2 my-lg-0">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search" />
                                                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form> */}
                                    </div>
                                </nav>

                            </div>
                        </div>


                        <br />
                        <div className="col-9 col-sm-9 col-lg-9 col-xl-9 col-xxl-9 py-4">
                            <h1 className="TituloCanaco py-5">Todo lo que necesitas conocer sobre Aspel ADM</h1>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">


                                    {/*Inicio configura y emite ADM */}
                                    {/* <CardDeck className="SombraCARDSNT pb-3" id="ADM1">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/3NFt5vrQEY4?si=YMkKTmChE0CSYmaL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2" >
                                                        Configuración CFDI
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Configura y emite correctamente CFDI 4.0 en Aspel ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck> */}
                                    {/*Termina Configura y emite  ADM */}
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">

                                    {/*Inicia Alta RFC ADM */}
                                    {/* <CardDeck className="SombraCARDSNT pb-3" id="ADM2">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/HvqWv2pdfps?si=hGTjrYTvZKxZK47Q?fs=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allow='fullscreen'></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta RFC
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de RFC en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck> */}
                                    {/*Termina Alta RFC ADM */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div >
                
            </>
        )
    }

}

export default Univdep;