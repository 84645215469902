import React from 'react';
import InfraTec from '../IMG/SolucionesTecnologicas/OCA_ST_4.jpg';
import SistBack from '../IMG/SolucionesTecnologicas/OCA_ST_3.jpg';
import SegPerim from '../IMG/SolucionesTecnologicas/OCA_ST_2.jpg';
import Bfiskur_1 from '../IMG/SolucionesTecnologicas/OCA_BF_1.jpg';
import Bfiskur_2 from '../IMG/SolucionesTecnologicas/OCA_BF_2.png';
import Bfiskur_3 from '../IMG/SolucionesTecnologicas/OCA_BF_3.png';
import Bfiskur_4 from '../IMG/SolucionesTecnologicas/OCA_BF_4.png';
import Bfiskur_5 from '../IMG/SolucionesTecnologicas/OCA_BF_5.png';
import Bfiskur_6 from '../IMG/SolucionesTecnologicas/OCA_BF_6.png';
import Bfiskur_7 from '../IMG/SolucionesTecnologicas/OCA_BF_7.png';
import SiigoAspel from '../IMG/SolucionesTecnologicas/OCA_SA_1.jpg';
import SiigoSAE from '../IMG/SolucionesTecnologicas/OCA_SA_2.png';
import SiigoNOI from '../IMG/SolucionesTecnologicas/OCA_SA_3.jpg';
import SiigoCOI from '../IMG/SolucionesTecnologicas/OCA_SA_4.jpg';
import SiigoBANCO from '../IMG/SolucionesTecnologicas/OCA_SA_5.jpg';
import LogoAspel from '../IMG/LogosAspel/LogoAspel.png';
// Importación de imagenes
import A1 from '../IMG/modal/icono-inf-tecnol-gica.png';
import L1 from '../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import L2 from '../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import L3 from '../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import L4 from '../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import L5 from '../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import L6 from '../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import L7 from '../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import A2 from '../IMG/modal/icon-backup.png';
import A3 from '../IMG/modal/icon-seguridad.png';
import BF1 from '../IMG/BFISKUR/cfdi-cancelados 1.png';
import BF2 from '../IMG/BFISKUR/comprobantes-pago-faltantesl 1.png';
import BF3 from '../IMG/BFISKUR/cumplimiento-obligaciones 1.png';
import BF4 from '../IMG/BFISKUR/efos 1.png';
import BF5 from '../IMG/BFISKUR/medidor-riesgo-fiscal 1.png';
import BF6 from '../IMG/BFISKUR/cfdi-recibido-reglas 1.png';
import BF7 from '../IMG/BFISKUR/repse-registro-proveedores 1.png';
import ServOCA from '../IMG/OCA/Servicios/ServiciosOCA.jpg';
import LogoAspel1 from '../IMG/LogosAspel/LogoAspel.png';
import '../Component/styles/Servicios.css';
import { Reveal, Tween } from 'react-gsap';
import B1 from '../IMG/Logo/Bfis.png';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter, CardHeader, Alert, Nav, NavItem, NavLink, Navbar
} from 'reactstrap';
import ServiciosOCA from '../paginas/DetallesEventos/Evento1';
import '../Component/styles/Canaco.css';
import viCana from '../Video/FondoCanaco/1.mp4';
import Menu2 from './Menu2';
import LogoOCA from '../IMG/Logo/oca-consultoria-logo-ii.png';
// import {Nav,  Navbar, NavDropdown } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

class Canaco extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <>
                <Menu2 />
                <div className="FondoCanaco">
                    <video src={viCana} loop muted autoplay="true" preload="auto" className="videoCanaco"></video>
                    <div className="row">
                        <div className="col-3 col-sm-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="card justify-content-center" style={{ background: 'transparent', position: 'fixed', padding: '0% 0', marginTop: '286px', zIndex: '1' }}>

                                <nav className="navbar navbar-expand-lg navbar-light bg-light nav flex-column SerInCana" style={{ position: 'fixed', zIndex: '1' }} >
                                    <a class="navbar-brand" href="#">Menu</a>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon" style={{border:''}}></span>
                                    </button>
                                    

                                    <div class="collapse navbar-collapse nav flex-column" id="navbarTogglerDemo03" style={{ width: '200px' }}>
                                        {/* <form class="form-inline my-2 my-lg-0">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search" />
                                                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form> */}
                                        {/* <a class="nav-link" href="#ADM1">Configura y emite correctamente CFDI 4.0 en Aspel ADM <span class="sr-only">(current)</span></a> */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM1" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Configura y emite correctamente CFDI 4.0 en Aspel ADM <span class="sr-only">(current)</span>
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM2" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de RFC en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM3" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de usuarios en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM4" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Cómo cargar los sellos digitales en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Alta de RFC en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Cómo cargar los sellos digitales en ADM</a> */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM5" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de Cliente con ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM6" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de Tercero con ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Alta de Cliente con ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta producto con ADM</a> */}



                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM7" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta producto con ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM8" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de Documento tipo Compra con ADM
                                        </Button>

                                        {/* **********++++++++ */}


                                        {/* <a class="nav-link" href="#">Alta de Tercero con ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de Documento tipo Compra con ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM9" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Notas de venta en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM10" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Movimientos de Entrada y Salida en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Notas de venta en ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Emisión de la factura global en ADM</a> */}



                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM11" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de la factura global en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM12" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Factura a Mostrador con y sin desglose de IVA con ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Movimientos de Entrada y Salida en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Factura a Mostrador con y sin desglose de IVA con ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM13" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Elaboración de una devolución en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM14" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración de impuestos en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Elaboración de una devolución en ADM <span class="sr-only">(current)</span></a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de Documento tipo Honorarios</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM15" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de Documento tipo Honorarios
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM16" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración Cliente complemento ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Configuración de impuestos en ADM <span class="sr-only">(current)</span></a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Configuración Cliente complemento ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM17" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de un comprobante de pago en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM18" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Configuración Notas de crédito en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Emisión de un comprobante de pago en ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Emisión de la factura con el complemento de Leyendas Fiscales en ADM</a> */}
                                        {/*  */}


                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM19" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Emisión de la factura con el complemento de Leyendas Fiscales en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM20" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de documento tipo notas de crédito
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Configuración Notas de crédito en ADM</a>

                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Alta de documento tipo notas de crédito</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM21" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Cancelación del CFDI en Aspel ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM22" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Comprobante de recepción de pagos v 2.0 en ADM
                                        </Button>

                                        {/* **********++++++++ */}


                                        {/* <a class="nav-link" href="#">Cancelación del CFDI en Aspel ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Como imprimir los documentos en ADM</a> */}

                                        {/*  */}

                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM23" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Como imprimir los documentos en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM24" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Consideraciones para la emisión del recibo de nómina en ADM
                                        </Button>

                                        {/* **********++++++++ */}

                                        {/* <a class="nav-link" href="#">Comprobante de recepción de pagos v 2.0 en ADM</a>
                                        <a class="nav-link disabled" href="#" tabind    ex="-1" aria-disabled="true">Consideraciones para la emisión del recibo de nómina en ADM</a> */}


                                        {/* <a class="nav-link" href="#">Cancelación del CFDI en Aspel ADM</a> */}


                                        {/* *************+*+ */}
                                        <Button
                                            className="nav-link text-left" href="#ADM25" outline style={{ background: 'transparent', border: 'transparent', color: 'blue' }}
                                        >
                                            Alta de documento Tipo Cotización en ADM
                                        </Button>

                                        <Button
                                            className="nav-link text-left" href="#ADM26" outline style={{ background: 'transparent', border: 'transparent', color: '#030303' }}
                                        >
                                            Alta de documento tipo Arrendamiento en ADM
                                        </Button>

                                        {/* **********++++++++ */}
                                        {/* <a class="nav-link" href="#">Alta de documento tipo Arrendamiento en ADM</a>
                                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Como imprimir los documentos en ADM</a> */}
                                        {/* <form class="form-inline my-2 my-lg-0">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search" />
                                                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form> */}
                                    </div>
                                </nav>

                            </div>
                        </div>


                        <br />
                        <div className="col-9 col-sm-9 col-lg-9 col-xl-9 col-xxl-9 py-4">
                            <h1 className="TituloCanaco py-5">Todo lo que necesitas conocer sobre Aspel ADM</h1>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">


                                    {/*Inicio configura y emite ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM1">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/3NFt5vrQEY4?si=YMkKTmChE0CSYmaL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2" >
                                                        Configuración CFDIs
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Configura y emite correctamente CFDI 4.0 en Aspel ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Configura y emite  ADM */}

                                    {/*Inicia Alta Usuarios ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM3">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/jkJ-FWA8Icg?si=fD0c9sBK71i0we1Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta usuarios
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de usuarios en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Alta Usuarios ADM */}

                                    {/*Inicia Alta Usuarios ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM5">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/NGJqnsgG7Yg?si=9KDQkLZ3LWXhSLRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta Clientes
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de Cliente con ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Alta Usuarios ADM */}

                                    {/*Inicia Alta Productos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM7">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/j1SOeGB4s_A?si=CAaj2JAy8LdkIcXE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta Productos
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta producto con ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Alta productos ADM */}

                                    {/*Inicia Notas de Venta ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM9">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/Nn_uDbyrq9I?si=HbE_xkknmSl_XlhM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Notas de Venta
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Notas de venta en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Notas de Venta ADM */}

                                    {/*Inicia Factura Global ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM11">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/Iea_kos7bpY?si=iUYsZ5mYbeGzbnjY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            {/* <iframe height="345" src="https://www.youtube.com/embed/jkJ-FWA8Icg?si=fD0c9sBK71i0we1Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allow='fullscreen'></iframe> */}
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Factura Global
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Emisión de la factura global en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Factura global ADM */}

                                    {/*Inicia Devolución ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM13">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/MaBpG_V_fvE?si=7zxmE_KzNJwbIPnk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Devolución ADM
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Elaboración de una devolución en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Devolución ADM */}

                                    {/*Inicia Honorarios ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM15">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/OCU6oe6-utY?si=gjAXPdfRn1hTEWYF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Honorarios
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de Documento tipo Honorarios.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Honorarios ADM */}

                                    {/*Inicia Configuración complementos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM17">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/vnhfEg008iM?si=4C0yxgZENvE3VnAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Complementos ADM
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Emisión de un comprobante de pago en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Configuración complementos ADM */}


                                    {/*Inicia Leyendas Fiscales ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM19">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/rEYg5In4zpU?si=-LrzLjTbuwfqwLWM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Leyendas Fiscales
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Emisión de la factura con el complemento de Leyendas Fiscales en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Leyendas ADM */}

                                    {/*Inicia Cancelaciones ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM21">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/EPdvTRKDNlo?si=hPkpzA_hVfO-ktmz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Cancelación CFDI
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Cancelación del CFDI en Aspel ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Cancelaciones ADM */}

                                    {/*Inicia Imprimir Documentos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM23">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/9BlHlcpBP1Q?si=2LlOTil329FV1uAr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Impresión CFDI
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Como imprimir los documentos en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Imprimir Documentos ADM */}

                                    {/*Inicia Cotización ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM25">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/GfU4nXaqj8c?si=_UJq1YQwTdxmtu0y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Cotización
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de documento Tipo Cotización en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Cotización ADM */}



                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">

                                    {/*Inicia Alta RFC ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM2">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/HvqWv2pdfps?si=hGTjrYTvZKxZK47Q?fs=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta RFC
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de RFC en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Alta RFC ADM */}

                                    {/*Inicia Sellos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM4">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/anbhOUSj8tw?si=WiOuDQHBViJvqfEL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Sellos Digitales
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Cómo cargar los sellos digitales en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Sello ADM */}

                                    {/*Inicia Terceros ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM6">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/iyjOiqINyTM?si=5qy59qr07Ekotuuh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Alta Terceros
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de Tercero con ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Terceros ADM */}

                                    {/*Inicia Compras ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM8">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/1Bn5TiCXk_4?si=XESNQfKLqRjSJV1n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Compras ADM
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de Documento tipo Compra con ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Compras ADM */}

                                    {/*Inicia Entradas y salidas ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM10">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/OXNN85b6hN0?si=fg8tSG90SNM43TsS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Entradas y Salidas
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Movimientos de Entrada y Salida en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Entradas y Salidas ADM */}

                                    {/*Inicia Factura Mostrador ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM12">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/TCA2mOKdd6o?si=P44zgGKX0okFTDdv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Factura Mostrador
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Factura a Mostrador con y sin desglose de IVA con ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Factura mostrador ADM */}

                                    {/*Inicia Impuestos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM14">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/zEmD5XMFuAw?si=Ua17pcb2vrm90BJu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Impuestos ADM
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Configuración de impuestos en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Impuestos ADM */}



                                    {/*Inicia Complemento cliente ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM16">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/e_hmR619tQw?si=T3Hp2vB_4h2HisB6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            {/* <iframe height="345" src="https://www.youtube.com/embed/EPdvTRKDNlo?si=hPkpzA_hVfO-ktmz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                            {/* <iframe height="345" src="https://www.youtube.com/embed/brSQ2qOGm00?si=Wwzj0nnafxicCTf8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Complemento Cliente
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Configuración Cliente complemento ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Complemento cliente ADM */}


                                    {/*Inicia Notas de Crédito ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM18">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/OjyaEJYUDDo?si=SIhyDO7yMmKBLYZy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Notas de crédito
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Configuración Notas de crédito en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Notas de crédito ADM */}

                                    {/*Inicia Notas de Crédito ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM20">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/vtT8oPqZDFQ?si=FrTyxlfmv_o51pJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Notas de crédito
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de documento tipo notas de crédito.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Notas de crédito ADM */}


                                    {/*Inicia Recepción de pagos ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM22">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/O6fGclta_dM?si=L7KPHgwdfhUAbcNk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Recepción de pagos
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Comprobante de recepción de pagos v 2.0 en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Recepción de pagos ADM */}

                                    {/*Inicia Recibos Nómina ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM24">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/pcgpNdtGs2Q?si=uEgTDKOj3POHMZIK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Recibo de nómina
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Consideraciones para la emisión del recibo de nómina en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Recibos Nómina ADM */}

                                    {/*Inicia Alta RFC ADM */}
                                    <CardDeck className="SombraCARDSNT pb-3" id="ADM26">
                                        <Card
                                            style={{
                                                width: '100%', borderRadius: '10px'
                                            }}
                                            className="p-0"
                                        >
                                            <iframe height="345" src="https://www.youtube.com/embed/PQQyYO_XETU?si=nYiPFG24_FfSyC8l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                            <CardBody>
                                                <CardTitle tag="h2" className="text-center" style={{ background: '#01587D' }}>
                                                    <p className="TitH9Canaco py-2">
                                                        Arrendamiento
                                                    </p>
                                                </CardTitle>
                                                <CardText className="text-center SerInCanaco" >
                                                    Alta de documento tipo Arrendamiento en ADM.
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </CardDeck>
                                    {/*Termina Alta RFC ADM */}






                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div >
            </>
        )
    }

}

export default Canaco;